import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Wrapper, Content } from "./styles.js";

import Button from "@atoms/Button";
import Logo from "@atoms/Logo/index.js";
import HorWrap from "@atoms/HorWrap";

const Header = ({ whiteLogo }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrollOnTop, setScrollOnTop] = useState(true);

  const toggleMenu = () => {
    let distanceFromTop = window.pageYOffset;

    return function () {
      let newDistanceFromTop = window.pageYOffset;
      if (newDistanceFromTop < 50 || distanceFromTop >= newDistanceFromTop) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
      if (newDistanceFromTop < 50) {
        setScrollOnTop(true);
      } else {
        setScrollOnTop(false);
      }
      distanceFromTop = newDistanceFromTop;
    };
  };

  useEffect(() => {
    setTimeout(() => setShowMenu(true), 500);
    window.addEventListener("scroll", toggleMenu());
    return window.removeEventListener("scroll", toggleMenu());
  }, []);

  return (
    <Wrapper menuShowed={showMenu} scrolled={!scrollOnTop}>
      <HorWrap>
        <Content>
          <Logo white={scrollOnTop} />
          <a href={`https://app.fplrank.live/`}>
            <Button size="sm" color="secondary">
              Go to app
            </Button>
          </a>
        </Content>
      </HorWrap>
    </Wrapper>
  );
};

Header.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
};

Header.defaultProps = {
  navItems: [],
};

export default Header;
