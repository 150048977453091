import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
    width: 100vw;
    font-family:  dicsans, sans-serif;
    font-size: 14px;
    font-weight: 300;
  }

  body {
    --black: #121212;
    --white: #ffffff;
    --gray: #afb8c1;
    --brand1: #14BB64;
    --brand2: #FCBB06;
    --brand3: #5303EF;
    --brand4: #F0F5F9;
    --brand5: #292524;
    --bg-color: var(--brand4);
    --bg-white-color: #ffffff;
    --black-text: #1d1d1d;
    --padding: 20px;
    --padding0: 24px;
    --padding1: 28px;
    --padding2: 32px;
    --topbarHeight: 72px;
    --topbarHeight1: 88px;
    position: relative;
    width: 100vw;
    margin: 0;
    padding: 0;
    font-family: dicsans, sans-serif;
    font-weight: 300;
    background-color: var(--bg-color);
    color: var(--black-text);
  }

  body.dark-theme {
    --white: #222222;
    --bg-color: #1D1D1D;
    --bg-white-color: #222222;
    --black-text: #ffffff;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--brand1) var(--bg-white-color);
  }
  body::-webkit-scrollbar-track {
    background: var(--bg-white-color);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--brand1) ;
    border-radius: 0;
    border: 0 solid var(--bg-white-color);
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.25;
    letter-spacing: -0.5px;
  }

  .custom-section h3 {
    margin: 0 0 16px;
    max-width: 400px;
    font-size: 16px;
    font-weight: 500;

    span {
      display: block;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    .custom-section section > div > div > div {
      align-items: flex-start;
      text-align: left;
    }

    .custom-section h2 {
      margin: 0 0 24px;
      font-size: 36px;
    }

    .custom-section h3 {
      margin: 0 0 24px;
      font-size: 22px;
    }

    .section3.custom-section h2 {
      margin: 0 0 24px;
      max-width: 400px;
    }

    .custom-section a {
      margin: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    .custom-section h2 {
      font-size: 48px;
    }

    .section3.custom-section h2 {
      max-width: 440px;
    }
  }
`;
