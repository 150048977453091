import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Wrapper, Tooltip } from "./styles";

const Button = ({
  type = "button",
  size,
  color,
  disabled,
  to,
  tooltip,
  onClick,
  children,
}) => {
  const handleOnClick = (e) => {
    onClick && onClick(e);
  };

  return (
    <Wrapper
      type={type}
      as={to ? Link : "button"}
      to={to}
      size={size}
      color={color}
      disabled={disabled}
      onClick={handleOnClick}
      props
    >
      {typeof children === "string" ? <p>{children}</p> : children}
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </Wrapper>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOf(["sm-square", "lg-square", "sm", "lg"]),
  color: PropTypes.oneOf(["white", "light", "primary", "secondary"]),
  disabled: PropTypes.bool,
  to: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Button;
