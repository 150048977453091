import styled, { css } from "styled-components";

export const Wrapper = styled.button`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  will-change: color, background-color, border-color;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: var(--brand1);
    content: "";
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.3s ease;
  }

  > * + * {
    margin-left: 4px;
  }

  svg {
    z-index: 1;
    position: relative;
    path {
      transition: fill 0.3s ease;
    }
  }

  p {
    z-index: 1;
    position: relative;
    white-space: nowrap;
  }

  p + svg,
  svg + p {
    margin-left: 8px;
  }

  ${({ size, theme }) => {
    switch (size) {
      case "sm-square":
        return css`
          min-width: 30px;
          height: 30px;
          padding: 2px;
          font-size: 11px;
        `;
      case "lg-square":
        return css`
          min-width: 40px;
          height: 40px;
          padding: 4px;
          font-size: 13px;
        `;
      case "sm":
        return css`
          height: 40px;
          padding: 16px 24px;
          font-size: 13px;
        `;
      case "lg":
        return css`
          height: 40px;
          padding: 16px 24px;
          font-size: 13px;

          @media (min-width: ${theme.breakpoints[1]}) {
            height: 54px;
            padding: 16px 32px;
            font-size: 18px;
          }
        `;
      default:
        return "";
    }
  }}
  ${({ color }) => {
    switch (color) {
      case "ghost":
        return css`
          background-color: rgba(0, 0, 0, 0);
          color: white;

          svg path {
            fill: white;
          }
        `;
      case "white":
        return css`
          background-color: rgba(0, 0, 0, 0);
          color: var(--brand1);

          svg path {
            fill: var(--brand1);
          }
        `;
      case "light":
        return css`
          background-color: var(--grey1);
          color: var(--brand1);

          svg path {
            fill: var(--brand1);
          }
        `;
      case "primary":
        return css`
          border-width: 0;
          color: white;

          svg path {
            fill: white;
          }

          &::before {
            opacity: 1;
          }

          &::after {
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 4px;
            width: 100%;
            height: 100%;
            background-color: var(--brand2);
            content: "";
            opacity: 0;
            will-change: opacity;
            transition: opacity 0.3s ease;
          }
        `;
      case "secondary":
        return css`
          background-color: var(--brand3);
          border-width: 0;
          color: white;

          svg path {
            fill: white;
          }

          &::after {
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--brand2);
            content: "";
            opacity: 0;
            will-change: opacity;
            transition: opacity 0.3s ease;
          }
        `;
      case "third":
        return css`
          background-color: var(--brand2);
          border-width: 0;
          color: white;

          svg path {
            fill: white;
          }

          &::after {
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--brand3);
            content: "";
            opacity: 0;
            will-change: opacity;
            transition: opacity 0.3s ease;
          }
        `;
      default:
        return css`
          background-color: rgba(0, 0, 0, 0);
        `;
    }
  }}

  &:hover {
    ${({ color }) =>
      css`
        border-color: ${color === "white" && "#11998e"};
        color: white;

        svg path {
          fill: white;
        }

        &::before {
          opacity: 1;
        }

        &::after {
          opacity: 1;
        }
      `}
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    &:hover aside {
      transform: translate3d(-50%, -120%, 0);
      opacity: 1;
    }
  }
`;

export const Tooltip = styled.aside`
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 4px;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  white-space: nowrap;
  transform: translate3d(-50%, -100%, 0);
  opacity: 0;
  transition: all 0.3s ease;
`;
