import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import shareImage from "@images/share-img.png";

import "@theme/sanitize.css";
import { mainTheme } from "@theme/theme";
import { GlobalStyle } from "./styles.js";

import Header from "@organisms/Header";
import Footer from "@organisms/Footer";

const LayoutOuter = ({ location, children }) => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  });

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>FPL mini leagues live tables | FPLrank.live</title>
        <meta
          name="description"
          content="No more waiting for FPL updates or clicking through teams – our app brings you real-time points scores, ensuring you're always up to date during matches"
        />
        <meta
          name="og:title"
          content="FPL mini leagues live tables | FPLrank.live"
        />
        <meta
          name="twitter:title"
          content="FPL mini leagues live tables | FPLrank.live"
        />
        <meta
          name="og:description"
          content="Instant standings for your FPL mini league. All the info you need in one view."
        />
        <meta
          name="twitter:description"
          content="Instant standings for your FPL mini league. All the info you need in one view."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="article:modified_time" content="2023-11-01T09:00:00Z" />
        <meta name="og:locale" content="en_EN" />
        <meta name="og:type" content="article" />
        <meta name="og:site_name" content="fplrank.live" />
        <meta property="og:url" content="https://fplrank.live/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="FPL mini leagues live tables | FPLrank.live"
        />
        <meta
          property="og:description"
          content="Instant standings for your FPL mini league. All the info you need in one view."
        />
        <meta name="og:image" content={shareImage} />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="fplrank.live" />
        <meta property="twitter:url" content="https://fplrank.live/" />
        <meta
          name="twitter:title"
          content="FPL mini leagues live tables | FPLrank.live"
        />
        <meta
          name="twitter:description"
          content="Instant standings for your FPL mini league. All the info you need in one view."
        />
        <meta name="twitter:image" content={shareImage} />
        <link rel="canonical" href="https://fplrank.live" />
        <link rel="stylesheet" href="https://use.typekit.net/rfb1crq.css" />
      </Helmet>
      <GlobalStyle />
      <Header active={pageLoaded} />
      {children}
      <Footer />
    </ThemeProvider>
  );
};

export default LayoutOuter;
