module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-clicky/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"101423560"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FPLrank.live","short_name":"FPLrank","start_url":"/","background_color":"#fff6ee","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0996c33e362bd16cbb9d132719299507"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
