import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";

const Link = ({ to, title, children }) => {
  return (
    <GatsbyLink to={`/${to}`} title={title}>
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Link.defaultProps = {
  to: "",
  title: "hiperlink",
  children: null,
};

export default Link;
