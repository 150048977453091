import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 32px 0;
  background: var(--black);
  color: white;
  foont-size: 12px;
  font-weight: 500;

  a {
    opacity: 0.9;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 44px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 68px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  }
`;

export const Content = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 24px;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div > p {
    margin: 12px 0;

    > a {
      margin: 0;
    }
  }
`;

export const Nav = styled.nav`
  margin: 24px 0 0;

  > h3 {
    margin: 0 0 12px;
  }

  > ul {
    display: flex;
    flex-direction: column;
    align-itmes: flex-end;
    justify-content: flex-end;

    > li {
      margin: 0 0 12px;

      > a {
        margin: 0;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: 0;
  }
`;

export const Credits = styled.div`
  font-size: 10px;
`;
