import React from "react";
import PropTypes from "prop-types";

import Logo from "@atoms/Logo";

import { Wrapper, Content, Main, Nav, Credits } from "./styles.js";

import HorWrap from "@atoms/HorWrap";
import { Link } from "gatsby";

const Footer = () => {
  const links1 = [
    {
      id: "about",
      name: "About",
    },
    {
      id: "faq",
      name: "FAQ",
    },
    {
      id: "contact",
      name: "Contact",
    },
  ];

  const links2 = [
    {
      id: "terms",
      name: "Terms and conditions",
    },
    {
      id: "privacy",
      name: "Privacy policy",
    },
    {
      id: "cookies",
      name: "Cookies",
    },
  ];

  return (
    <Wrapper>
      <HorWrap>
        <Content>
          <Main>
            <div>
              <Logo />
              <p>
                <a href="mailto:contact@fplrank.live" target="_blank">
                  contact@fplrank.live
                </a>
              </p>
              <p>
                <a href="https://twitter.com/FPLrank_live" target="_blank">
                  X/fplrank_live
                </a>
              </p>
            </div>
            {/* <Credits>Proudly developed in Poland</Credits> */}
          </Main>
          <Nav>
            <ul>
              {links1.map((link) => (
                <li>
                  <Link to={`/${link.id}`}>{link.name}</Link>
                </li>
              ))}
            </ul>
          </Nav>
          <Nav>
            <ul>
              {links2.map((link) => (
                <li>
                  <Link to={`/${link.id}`}>{link.name}</Link>
                </li>
              ))}
            </ul>
          </Nav>
        </Content>
      </HorWrap>
    </Wrapper>
  );
};

Footer.propTypes = {
  active: PropTypes.bool,
};

Footer.defaultProps = {
  active: true,
};

export default Footer;
