import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";

export const Wrapper = styled.button`
  svg {
    position: relative;
    width: auto;
    height: 32px;

    path {
      transition: fill 0.6s ease;
    }
  }

  ${({ white }) =>
    white &&
    css`
      svg path:nth-child(2) {
        fill: var(--white);
      }
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    height: 40px;
  }
`;
