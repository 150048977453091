import React from "react";
import PropTypes from "prop-types";
import Link from "@atoms/Link";
import SVG from "react-inlinesvg";

import { Wrapper } from "./styles.js";

import LogoSVG from "@images/logo.svg";

const Logo = ({ white }) => {
  return (
    <Wrapper white={white}>
      <Link to="" title="Go to index">
        <SVG src={LogoSVG} />
      </Link>
    </Wrapper>
  );
};

Logo.propTypes = {
  white: PropTypes.bool,
};

Logo.defaultProps = {
  white: false,
};

export default Logo;
