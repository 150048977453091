import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: 0 var(--padding2);
  max-width: 1120px;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 0 var(--padding0);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 0 var(--padding1);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 0 var(--padding2);
  }
`;
