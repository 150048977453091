import styled, { css } from "styled-components";

export const Wrapper = styled.header`
  z-index: ${({ theme }) => theme.zindex.header};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100vw;
  height: var(--topbarHeight);
  will-change: transform, color;
  transition: transform 0.6s ease, color 0.3s 0.3s ease;
  color: ${({ scrolled }) => (scrolled ? "var(--brand1)" : "white")};
  transform: translate3d(
    0,
    ${({ menuShowed }) => (menuShowed ? "0" : "-100%")},
    0
  );

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    box-shadow: 0 30px 45px -25px rgba(22, 22, 22, 0.15);
    opacity: ${({ scrolled }) => (scrolled ? "1" : "0")};
    will-change: opacity;
    transition: opacity 0.3s 0.3s ease;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: var(--topbarHeight1);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  > h1 {
    margin-left: 70px;
    font-size: 36px;
    line-height: 60px;
    font-weight: 500;
  }

  > div > a {
    display: none;

    &:nth-child(1) {
      margin-right: 30px;
    }
  }

  a {
    font-weight: 500;
    transition: color 0.3s ease;
    will-change: color;

    &:hover {
      color: var(--brand1);
    }
  }

  nav {
    position: absolute;
    left: 50%;
    display: none;
    transform: translate3d(-50%, 0, 0);

    ul {
      display: flex;

      li {
        position: relative;
        margin-right: 35px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
